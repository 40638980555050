<template>
  <div class="layout-main detail-sub-page journey-detail-submit">
    <div class="journey">
      <div class="page-nav">
        <router-link class="back-btn" :to="{name: 'JourneyDetail'}">
          <Icon name="arrow_back_ios" size="24" />
        </router-link>
        <h2 class="fs-h2">申請資訊</h2>
      </div>
      <div class="page-content">
        <div class="application-group">
          <div class="title fs-title-14">入園證</div>
          <div class="desc fs-body-14" v-if="passRule.areaHouseRule.houseName">
            <span v-text="passRule.areaHouseRule.houseName">排雲山莊</span>
            <span class="remark"> [ 預先登記後抽籤 ]</span>
          </div>
          <div class="desc d-flex fs-body-14" v-for="(item, index) in parkPassDesc" :key="index">
            <div :class="['desc-flex-item' ,'date-time' , item.date.length > 5 ? 'range' : '']" v-if="item.date">
              <span v-text="item.date"></span>
            </div>
            <div class="desc-flex-item date-time" v-if="item.time">
              <span v-text="item.time"></span>
            </div>
            <div class="desc-flex-item" v-if="item.text">
              <span v-text="item.text"></span>
            </div>
            <div class="desc-flex-item" v-if="item.link">
              <a target="_blank" :href="item.link"><span v-text="item.linkText"></span></a>
            </div>
          </div>
        </div>
        <div class="application-group">
          <div class="title fs-title-14">警政署入山證</div>
          <div class="desc d-flex fs-body-14" v-for="(item, index) in mountainPassDesc" :key="index">
            <div :class="['desc-flex-item' ,'date-time' , item.date.length > 5 ? 'range' : '']" v-if="item.date">
              <span v-text="item.date"></span>
            </div>
            <div class="desc-flex-item date-time" v-if="item.time">
              <span v-text="item.time"></span>
            </div>
            <div class="desc-flex-item" v-if="item.text">
              <span v-text="item.text"></span>
            </div>
            <div class="desc-flex-item" v-if="item.link">
              <a target="_blank" :href="item.link"><span v-text="item.linkText"></span></a>
            </div>
          </div>
        </div>
        <div class="application-group">
          <div class="title fs-title-14">自然保護區域進入申請</div>
          <div class="desc d-flex fs-body-14" v-if="viaProtectAreas.length > 0">
            <div :class="['desc-flex-item' ,'date-time' , 'range']">
              <span v-text="protectAreaSubmitPeriodString"></span>
            </div>
            <div class="desc-flex-item">
              <span>申請進入自然保護區域</span>
            </div>
            <div class="desc-flex-item">
              <a target="_blank" href="https://pa.forest.gov.tw/"><span>前往申請</span></a>
            </div>
          </div>

          <div class="desc d-flex fs-body-14" v-if="viaProtectAreas.length === 0">
            <div class="desc-flex-item">
              <span>不須申請</span>
            </div>
          </div>
        </div>
        <div class="application-group">
          <div class="title fs-title-14">備註</div>
          <div class="desc fs-body-14">
            <ol>
              <li>非單攻行程需申請到山屋，才視為已申請入園證。</li>
              <li>填寫申請第一天留宿點時，會一併申請每日行程的留宿點。</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import setHideNav from "@/assets/js/setHideNav";

export default {
  mixins: [setHideNav],
  name: 'JourneyDetailSubmit',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      pageId: null,
      journey: null,
      passRule: null,
      parkPassDesc: [],
      parkSubmitDate: '',
      parkSubmitUrl: '',
      mountainPassDesc: [],
    };
  },
  props: {
  },
  components: {
  },
  computed: {
    viaProtectAreas: function() {
      return this.journey.journey.reduce(function(ary, item) {
        if (item.type !== 'milestone') {
          return ary;
        }
        const {protectArea} = item.detail;
        for (const p of protectArea) {
          if (ary.indexOf(p) === -1) {
            ary.push(p);
          }
        }
        return ary;
      }, []);
    },
	},
  watch: {
    
  },
  created() {
	},
  async mounted() {
    this.pageId = this.$route.params.id;
    await this.refresh(this.pageId);
    this.__calcParkPassDesc();
    this.__calcMointainPassDesc();
  },
  destroyed() {
  },
  methods: {
    async refresh(journeyId) {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        this.journey = await this.$store.dispatch('api/getJourneyDetailPromise', journeyId);
        this.passRule = await this.$store.dispatch('api/getJourneyPassRulePromise', journeyId);
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    setParkSubmitCalendar(date, time, url) {
      const temp = moment(`${date.format("YYYY-MM-DD")} ${time}:00+08:00`);
      this.parkSubmitDate = temp.utc().format("YYYYMMDD[T]HHmmss[Z]");
      this.parkSubmitUrl = url;
    },
    __calcParkPassDesc() {
      let d = moment(this.journey.startDate);
      const originDate = d.date();

      if (this.journey.durationDays > 1) {
        // 多日
        if (this.passRule.areaHouseRule !== null) {
          // 第一天住宿點為山屋且有設定區域
          this.parkPassDesc.push({
            text: this.passRule.areaHouseRule.houseName,
          });
          const areaRule = this.passRule.areaHouseRule;

          const endTime = moment(this.journey.startDate).subtract(5, 'day');
          endTime.set('hour', parseInt(areaRule.regCloseTime.split(':')[0]));
          endTime.set('minute', parseInt(areaRule.regCloseTime.split(':')[1]));
          let timeOver = false;
          if (moment().isAfter(endTime)) {
            timeOver = true;
          }

          if (areaRule.drawType === 0) {
            // 抽籤
            d.subtract(areaRule.regOpenDateRule === 0?2:1, 'month');
            if (d.date() !== originDate) {
              d.add(1, 'day');
            }
            const startRegMsg = {
              date: d.format('MM/DD'),
              time: areaRule.regOpenTime,
              text: '開始登記',
            };
            if (!timeOver) {
              startRegMsg.link = areaRule.regLink;
              startRegMsg.linkText = '前往登記';
              this.setParkSubmitCalendar(d, areaRule.regOpenTime, areaRule.regLink);
            }
            this.parkPassDesc.push(startRegMsg);

            d = moment(this.journey.startDate).subtract(1, 'month');
            if (d.date() !== originDate) {
              d.add(1, 'day');
            }
            const drawMsg = {
              date: d.format('MM/DD'),
              time: areaRule.drawTime,
              text: '隨機抽籤',
            }
            if (!timeOver) {
              drawMsg.link = areaRule.regResultUrl.replace('{date}', moment(this.journey.startDate).format('YYYY-MM-DD'));
              drawMsg.linkText = '抽籤結果';
            }
            this.parkPassDesc.push(drawMsg);

            let dFrom = d.add(1, 'day');
            let dTo = moment(this.journey.startDate).subtract(6, 'day');
            const resMsg = {
              date: dFrom.format('MM/DD') + ' ~ ' + dTo.format('MM/DD'),
              text: '登記補抽',
            }
            if (!timeOver) {
              resMsg.link = areaRule.regLink;
              resMsg.linkText = '前往補抽';
            }
            this.parkPassDesc.push(resMsg);

            this.parkPassDesc.push({
              date: dTo.add(1, 'day').format('MM/DD'),
              time: areaRule.regCloseTime,
              text: '截止遞補',
            });
          } else {
            // 照順序
            d.subtract(areaRule.regOpenDateRule === 0?2:1, 'month');
            if (d.date() !== originDate) {
              d.add(1, 'day');
            }
            const startRegMsg = {
              date: d.format('MM/DD'),
              time: areaRule.regOpenTime,
              text: '依申請時間排序',
            };
            if (!timeOver) {
              startRegMsg.link =  areaRule.regLink;
              startRegMsg.linkText = '前往申請';
              this.setParkSubmitCalendar(d, areaRule.regOpenTime, areaRule.regLink);
            }
            this.parkPassDesc.push(startRegMsg);

            let dFrom = d.add(1, 'day');
            let dTo = moment(this.journey.startDate).subtract(6, 'day');
            const reg2Msg = {
              date: dFrom.format('MM/DD') + ' ~ ' + dTo.format('MM/DD'),
              text: '登記遞補',
            };
            if (!timeOver) {
              reg2Msg.link =  areaRule.regLink;
              reg2Msg.linkText = '前往登記';
            }
            this.parkPassDesc.push(reg2Msg);

            this.parkPassDesc.push({
              date: dTo.add(1, 'day').format('MM/DD'),
              time: areaRule.regCloseTime,
              text: '截止遞補',
            });
          }
        } else {
          // 沒有山屋或沒有區域資料
          if (this.passRule.parkPass) {
            this.parkPassDesc.push({
              text: '此路線須申請入園證',
            });
          } else {
            this.parkPassDesc.push({
              text: '此路線不須申請入園證',
            });
          }
        }
      } else {
        // 單攻
        if (this.passRule.parkPass) {
          if (this.passRule.areaHouseRule !== null) {
            const areaRule = this.passRule.areaHouseRule;

            const endTime = moment(this.journey.startDate).subtract(5, 'day');
            endTime.set('hour', parseInt(areaRule.regCloseTime.split(':')[0]));
            endTime.set('minute', parseInt(areaRule.regCloseTime.split(':')[1]));
            let timeOver = false;
            if (moment().isAfter(endTime)) {
              timeOver = true;
            }

            d.subtract(areaRule.regOpenDateRule === 0?2:1, 'month');
            if (d.date() !== originDate) {
              d.add(1, 'day');
            }
            const regMsg = {
              date: d.format('MM/DD'),
              time: areaRule.regOpenTime,
              text: '開始申請',
            }
            if (!timeOver) {
              regMsg.link = areaRule.regLink;
              regMsg.linkText = '前往登記';
              this.setParkSubmitCalendar(d, areaRule.regOpenTime, areaRule.regLink);
            }
            this.parkPassDesc.push(regMsg);

            d = moment(this.journey.startDate).subtract(5, 'day');
            this.parkPassDesc.push({
              date: d.format('MM/DD'),
              time: areaRule.regCloseTime,
              text: '截止申請',
            });
          } else {
            this.parkPassDesc.push({
              text: '此路線須申請入園證',
            });
          }
        } else {
          this.parkPassDesc.push({
            text: '此路線不須申請入園證',
          });
        }
      }
    },
    __calcMointainPassDesc() {
      if (this.passRule.mountainPass) {
        // 需要入山證
        let d = moment(this.journey.startDate).subtract(124, 'day');
        let d2= moment(this.journey.startDate).subtract(3, 'day');

        d2.set('hour', 23);
        d2.set('minute', 59);
        let timeOver = false;
        if (moment().isAfter(d2)) {
          timeOver = true;
        }

        let dateStr = `${d.format('MM/DD')} ~ ${d2.format('MM/DD')}`;
        const regMsg = {
          date: dateStr,
          text: '申請入山證',
        }
        if (!timeOver) {
          regMsg.link = 'https://nv2.npa.gov.tw/NM107-604Client/';
          regMsg.linkText = '前往申請';
        }
        this.mountainPassDesc.push(regMsg);
      } else {
        // 不需要入山證
        this.mountainPassDesc.push({
          text: '此路線不須申請入山證',
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
@import "src/assets/scss/detailSubPage";
.journey-detail-submit {
  .page-content {
    .application-group {
      margin-bottom: 2rem;
    }
    .title {
      display: inline-block;
      color: $color-bk-tp-85;
      background: $color-primary-darkyellow;
      margin-bottom: 1rem;
    }
    .desc {
      margin-bottom: .5rem;
      &.d-flex {
        .desc-flex-item {
          white-space: nowrap;
          padding: 0 8px;
          &:first-child {
            padding-left: 0;
          }
          &.date-time {
            width: 55px;
            border-right: 1px solid $color-black-300;
            text-align: justify;
            &:first-child {
              width: 47px;
            }
            &.range {
              width: 110px;
              &:first-child {
                width: 102px;
              }
            }
          }
        }
      }
    }
    a {
      color: $color-link;
      &:hover {
        color: $color-link;
      }
    }
    ol {
      list-style: none;
      counter-reset: item;
      padding-left: 5px;
      li:before {
        content: counter(item) ". ";
        counter-increment: item;
        display: inline-block;
        width: 15px;
      }
    }
    .remark {
      color: $color-bk-tp-50;
    }
  }
}
</style>
